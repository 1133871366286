/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import Input, { type InputOnChange, type InputValue } from '../../ui/Input'
import Button from '../../ui/Button'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { useIonRouter } from '@ionic/react'
import getErrorMessage from '#utils/getErrorMessage'
import { useTranslation } from 'react-i18next'
import InputPasswordTest from '#components/ui/InputPasswordTest'
import useAuthState from '#hooks/useAuthState'
import { useHistory } from 'react-router'

export default function LoginForm(): JSX.Element {
  const [email, setEmail] = useState<InputValue>('')

  const [password, setPassword] = useState<InputValue>('')

  const { loginWithEmail, loginWithToken } = useAuthState()

  const { startLoading, stopLoading } = useLoading('signingIn')

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  const hisotry = useHistory()

  const { i18n } = useTranslation()

  const handleChanges: InputOnChange = (e) => {
    const { name, value } = e.target

    if (name === 'email') setEmail(value)
    if (name === 'password') setPassword(value)
  }

  // const testHandleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
  //   e.preventDefault()
  //   loginWithToken(
  //     'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcyNDQwMDEyNSwiZXhwIjoxNzI0NDAzNzI1LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay0yZ3N2bEB0YWNrcGF5LTY4OTA2LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstMmdzdmxAdGFja3BheS02ODkwNi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6ImI3MjVlNDgwLTA4MWQtNDcyMy05MzE5LTczMDY2MDg1ZGY2YiJ9.o1b8CQ7XgcW1Ej88soArAAh1GugEuen8gY3FamPfFtvtgn7gxNm_8MlvbosVZfYk-U3AyLE_QFBKYH6rTEzGdOp-B5Na-st93FGFfATMSqtarwnIVZlZ7Q9WPdK7mpriWhR1qiTeBcifDRIClyazdK7JKET56b9OW32JuLPKYov7a2_fjK_U4S2KEENNFSnW5sHJIp_Xc013itOm_hwKDBXS1iB8Pv65XPyErpK1BHgmTWlod0QlxnMtlSFS5BNgWN9yKG6PPoIj_X9s0tK7qIH6V86Flc-42XvTQsJGZsaD7gQA4okbg3jax-YNPUvktng4qXjkOsFbcvlijNNC7g'
  //   )
  //     .then((res) => {
  //       i18n
  //         .changeLanguage(res?.lang ?? 'en')
  //         .then(() => {
  //           stopLoading()
  //           console.log('User fetched')
  //           hisotry.push(`/dashboard/${res.category}/home`)
  //         })
  //         .catch((error) => {
  //           console.log(error)
  //           stopLoading()
  //           showToast(undefined, getErrorMessage(error))
  //         })
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       stopLoading()
  //       showToast(undefined, getErrorMessage(error))
  //     })
  // }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (email === '' && password === '') {
      showToast(undefined, 'login.emptyFields')
    } else if (email === '' || typeof email !== 'string') {
      showToast(undefined, 'login.emptyEmail')
    } else if (password === '' || typeof password !== 'string') {
      showToast(undefined, 'login.emptyPassword')
    } else {
      startLoading()
      loginWithEmail(email, password)
        .then((res) => {
          i18n
            .changeLanguage(res?.lang ?? 'en')
            .then(() => {
              stopLoading()
              console.log('User fetched')
              push(`/dashboard/${res.category}/home`, 'root')
            })
            .catch((error) => {
              console.log(error)
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            })
        })

        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <form className='space-y-8' onSubmit={handleSubmit}>
      <div>
        <Input
          value={email}
          onIonInput={handleChanges}
          name='email'
          type='email'
          labelOptions={{
            children: 'email'
          }}
          data-testid='input-email'
        />
      </div>
      <div>
        <InputPasswordTest
          data-testid='input-password'
          name='password'
          password={password}
          onPasswordChange={handleChanges}
          missingPassword
          showValidityPass={false}
        />
      </div>

      <div className='w-full pt-5'>
        <Button className='w-full ion-no-margin' type='submit'>
          continue
        </Button>
      </div>
    </form>
  )
}
